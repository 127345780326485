<template>
  <div
    v-if="!isLoading && isCodeChecked"
    class="auth-wrapper auth-v1 px-2 register-page"
  >
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <AppLogoWithText />
        </b-link>

        <b-card-text
          v-if="conf.is_invite_only && !isCodeProvided"
          class="mb-2"
        >
          Exciting news! Our services are in high demand, and we want to make sure you're among the first to get access when spots become available. We've created a waiting list, and we'd love to add you to it. Join our waiting list by providing your email address to secure your spot. You will be at the front of the line when we open up new memberships.
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="registerForm"
        >

          <b-alert
            v-show="message"
            class="mt-2"
            :variant="alertVariant"
            show
          >
            <div class="alert-body">
              <span>{{ message }}</span>
            </div>
          </b-alert>
          <b-form
            v-show="!hideForm"
            class="auth-register-form"
            @submit.prevent="onSubmit"
          >
            <!-- email -->
            <b-form-group
              v-if="!isCodeProvided"
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  :name="`${componentId}-confirm-email`"
                  placeholder="john@example.com"
                  autocomplete="one-time-code"
                />
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              v-else
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  :value="regEmail"
                  :state="errors.length > 0 ? false:null"
                  :name="`${componentId}-confirm-email`"
                  :readonly="true"
                  autocomplete="one-time-code"
                />
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="!conf.is_invite_only || isCodeProvided"
              label="First Name"
              label-for="first_name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="first_name"
                  v-model="firstName"
                  :state="errors.length > 0 ? false:null"
                  :name="`${componentId}-first-name`"
                  autocomplete="one-time-code"
                />
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="!conf.is_invite_only || isCodeProvided"
              label="Last Name"
              label-for="last_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="last_name"
                  v-model="lastName"
                  :state="errors.length > 0 ? false:null"
                  :name="`${componentId}-last-name`"
                  autocomplete="one-time-code"
                />
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="!conf.is_invite_only || isCodeProvided"
              label="Company Name"
              label-for="company_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Company Name"
              >
                <b-form-input
                  id="company_name"
                  v-model="companyName"
                  :state="errors.length > 0 ? false : null"
                  :name="`${componentId}-company-name`"
                  autocomplete="one-time-code"
                />
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              v-if="!conf.is_invite_only || isCodeProvided"
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :name="`${componentId}-register-password`"
                    placeholder="············"
                    autocomplete="one-time-code"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              v-if="!conf.is_invite_only || isCodeProvided"
              label="Confirm Password"
              label-for="confirm_password"
              autocomplete="one-time-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|password:@Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirm_password"
                    v-model="confirmPassword"
                    :type="confirmPasswordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :name="`${componentId}-confirm-password`"
                    placeholder="············"
                    autocomplete="one-time-code"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="confirmPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger tw-absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <validation-provider
              #default="{ errors }"
              name="Terms"
              rules="required:true"
            >
              <!-- checkbox -->
              <b-form-group class="tw-mt-5">
                <b-form-checkbox
                  v-model="status"
                  :state="errors.length > 0 ? false:null"
                  name="terms"
                >
                  I agree to
                  <b-link
                    target="_blank"
                    href="https://sellerxray.com/privacy"
                  >
                    Privacy Policy
                  </b-link>
                  &
                  <b-link
                    target="_blank"
                    href="https://sellerxray.com/terms"
                  >
                    Terms
                  </b-link>
                  .
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>

            <vue-programmatic-invisible-google-recaptcha
              ref="recaptcha"
              element-id="recaptcha"
              badge-position="right"
              :sitekey="siteKey"
              :show-badge-mobile="false"
              :show-badge-desktop="true"
              @recaptcha-callback="recaptchaCallback"
            />

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              <div v-if="loading">
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  small
                  label="Small Spinner"
                  type="grow"
                />
              </div>
              <div v-else>
                Sign up
              </div>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <span>Already have an account? Sign in</span>
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BCard, BLink, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import { toggleConfirmPasswordVisibility, togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ref } from '@vue/composition-api'
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'
import store from '@/store'
import AppLogoWithText from '@/components/AppLogoWithText.vue'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    AppLogoWithText,
    // BSV
    BCard,
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
    VueProgrammaticInvisibleGoogleRecaptcha,
  },
  mixins: [togglePasswordVisibility, toggleConfirmPasswordVisibility],
  props: {
    code: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    const { appTextLogoImage } = $themeConfig.app
    return {
      siteKey: '6LfM4KMkAAAAAASqDOHDphXk-Dd7tFVrKQ_xGF1t',
      regEmail: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      companyName: '',
      status: false,
      loading: false,
      message: null,
      recaptchaResponseToken: null,
      componentId: null,
      alertVariant: 'success',
      isCodeChecked: true,

      // validation rules
      required,
      email,
      appTextLogoImage,
    }
  },
  setup() {
    if (window.location.search.includes('referral_code')) {
      const urlParams = new URLSearchParams(window.location.search)
      const referralCode = urlParams.get('referral_code')
      if (referralCode) {
        localStorage.setItem('referral_code_expiry', new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000))
        localStorage.setItem('referral_code', referralCode)
      }
    }

    const isLoading = ref(true)
    const conf = ref({
      is_invite_only: false,
    })
    store.dispatch('systemSettings/fetchAppConf', { key: 'sign_up' }).then(data => {
      conf.value = data
    }).finally(() => {
      isLoading.value = false
    })
    return { isLoading, conf }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    hideForm() {
      return this.message !== null && this.alertVariant === 'success'
    },
    isCodeProvided() {
      return this.code && this.code.length
    },
  },
  mounted() {
    if (this.isCodeProvided) {
      this.isCodeChecked = false
      this.$store.dispatch('auth/checkCode', { code: this.code }).then(data => {
        this.regEmail = data.email
        this.isCodeChecked = true
      }).catch(() => {
        this.$router.replace({ name: 'error-404' })
      })
    }
    this.componentId = this.generateRandomString()
  },
  methods: {
    generateRandomString() {
      const length = 8
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let retVal = ''
      for (let i = 0, n = charset.length; i < length;) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
        i += 1
      }
      return retVal
    },
    onSubmit() {
      if (process.env.NODE_ENV === 'local') {
        this.validationForm()
        return
      }

      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleError() {
      this.$refs.registerForm.setErrors({
        email: ['Captcha is required'],
      })
    },
    recaptchaCallback(token) {
      if (token) {
        this.recaptchaResponseToken = token
        this.validationForm()
      } else {
        this.handleError()
      }
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success && this.status) {
          if (this.conf.is_invite_only) {
            if (this.isCodeProvided) {
              this.signUpWithCode()
            } else {
              this.signUpWaitingList()
            }
          } else {
            this.signUp()
          }
        } else if (!this.status) {
          this.$refs.registerForm.setErrors({
            Terms: [''],
          })
        }
      })
    },
    signUpWaitingList() {
      this.loading = true
      this.$store.dispatch('auth/requestInvitation', {
        email: this.regEmail,
        recaptcha: process.env.NODE_ENV === 'local' ? 'letmein#' : this.recaptchaResponseToken,
      }).then(
        result => {
          this.alertVariant = 'success'
          this.message = result
        }, e => {
          if (e.response.data.statusCode === 409) {
            this.$refs.registerForm.setErrors({
              Email: [e.response.data.message],
            })
          } else {
            this.alertVariant = 'danger'
            this.message = e.response.data.message
          }
        },
      ).finally(() => {
        this.loading = false
      })
    },
    getReferrerCode() {
      const code = localStorage.getItem('referral_code')
      const expiry = localStorage.getItem('referral_code_expiry')

      if (!code || (expiry && new Date(expiry) < new Date())) {
        localStorage.removeItem('referral_code')
        localStorage.removeItem('referral_code_expiry')
        return null
      }

      return code
    },
    signUp() {
      this.loading = true

      this.$store.dispatch('auth/register', {
        email: this.regEmail,
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.companyName,
        password: this.password,
        recaptcha: process.env.NODE_ENV === 'local' ? 'letmein#' : this.recaptchaResponseToken,
        referrer_code: this.getReferrerCode(),
      }).then(
        () => {
          this.$store.commit('auth/updateLoginFormSuccessMessage', 'Thanks! Your account was created. Please check your email to confirm your account.')
          this.$router.push({ name: 'auth-login' })
        }, e => {
          if (e.response.data.statusCode === 409) {
            this.$refs.registerForm.setErrors({
              Email: [e.response.data.message],
            })
          } else {
            this.alertVariant = 'danger'
            this.message = e.response.data.message
          }
        },
      ).finally(() => {
        this.loading = false
      })
    },
    signUpWithCode() {
      this.loading = true
      this.$store.dispatch('auth/register', {
        code: this.code,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.companyName,
        recaptcha: process.env.NODE_ENV === 'local' ? 'letmein#' : this.recaptchaResponseToken,
        referrer_code: this.getReferrerCode(),
      }).then(
        () => {
          this.$store.commit('auth/updateLoginFormSuccessMessage', 'Your SellerXray account has been created. Use your credentials to sign in below.')
          this.$router.push({ name: 'auth-login' })
        }, e => {
          if (e.response.data.statusCode === 409) {
            this.$refs.registerForm.setErrors({
              Password: [e.response.data.message],
            })
          } else {
            this.alertVariant = 'danger'
            this.message = e.response.data.message
          }
        },
      ).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.pointer-events-none {
  pointer-events: none;
}
</style>
