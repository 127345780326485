var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.isCodeChecked)?_c('div',{staticClass:"auth-wrapper auth-v1 px-2 register-page"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('AppLogoWithText')],1),(_vm.conf.is_invite_only && !_vm.isCodeProvided)?_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Exciting news! Our services are in high demand, and we want to make sure you're among the first to get access when spots become available. We've created a waiting list, and we'd love to add you to it. Join our waiting list by providing your email address to secure your spot. You will be at the front of the line when we open up new memberships. ")]):_vm._e(),_c('validation-observer',{ref:"registerForm"},[_c('b-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.message),expression:"message"}],staticClass:"mt-2",attrs:{"variant":_vm.alertVariant,"show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.message))])])]),_c('b-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideForm),expression:"!hideForm"}],staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(!_vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-confirm-email"),"placeholder":"john@example.com","autocomplete":"one-time-code"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,928634930)})],1):_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","value":_vm.regEmail,"state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-confirm-email"),"readonly":true,"autocomplete":"one-time-code"}}),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1421420404)})],1),(!_vm.conf.is_invite_only || _vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-first-name"),"autocomplete":"one-time-code"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,175145743)})],1):_vm._e(),(!_vm.conf.is_invite_only || _vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-last-name"),"autocomplete":"one-time-code"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3560793567)})],1):_vm._e(),(!_vm.conf.is_invite_only || _vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"Company Name","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"Company Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company_name","state":errors.length > 0 ? false : null,"name":(_vm.componentId + "-company-name"),"autocomplete":"one-time-code"},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1814777458)})],1):_vm._e(),(!_vm.conf.is_invite_only || _vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-register-password"),"placeholder":"············","autocomplete":"one-time-code"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,115466084)})],1):_vm._e(),(!_vm.conf.is_invite_only || _vm.isCodeProvided)?_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm_password","autocomplete":"one-time-code"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|password:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm_password","type":_vm.confirmPasswordFieldType,"state":errors.length > 0 ? false:null,"name":(_vm.componentId + "-confirm-password"),"placeholder":"············","autocomplete":"one-time-code"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":_vm.toggleConfirmPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger tw-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2294039730)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Terms","rules":"required:true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"tw-mt-5"},[_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null,"name":"terms"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"target":"_blank","href":"https://sellerxray.com/privacy"}},[_vm._v(" Privacy Policy ")]),_vm._v(" & "),_c('b-link',{attrs:{"target":"_blank","href":"https://sellerxray.com/terms"}},[_vm._v(" Terms ")]),_vm._v(" . ")],1)],1)]}}],null,false,2654645863)}),_c('vue-programmatic-invisible-google-recaptcha',{ref:"recaptcha",attrs:{"element-id":"recaptcha","badge-position":"right","sitekey":_vm.siteKey,"show-badge-mobile":false,"show-badge-desktop":true},on:{"recaptcha-callback":_vm.recaptchaCallback}}),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[(_vm.loading)?_c('div',[_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","label":"Small Spinner","type":"grow"}}),_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","label":"Small Spinner","type":"grow"}}),_c('b-spinner',{attrs:{"small":"","label":"Small Spinner","type":"grow"}})],1):_c('div',[_vm._v(" Sign up ")])])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v("Already have an account? Sign in")])])],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }